import { graphql, Link } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const PressItem = ({ data, pageContext }) => {
    const { frontmatter, body } = data.mdx
    const { previous, next } = pageContext
    const images = data.mdx.frontmatter.embeddedImagesLocal
    const imageCover = data.mdx.frontmatter.embeddedCoverImageLocal

    return (
        <Layout>
            <Seo title={frontmatter.title} />

            <div className="row g-0 ">
                <div className="d-grid">
                    <GatsbyImage className=" hero-image " image={getImage(imageCover[0])} alt="" />
                </div>
            </div>

            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3 ">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>{frontmatter.title}</h1>
                        <div className="d-flex">
                            <div className="flex-grow-1">
                                <p>
                                    {frontmatter.date}, by {frontmatter.author}
                                </p>
                            </div>
                            <div className="text-nowrap">
                                <Link to={`/news`}>
                                <svg width="0.5em" style={{marginRight:"1px", marginBottom:"3px"}} aria-hidden="true" focusable="false" data-prefix="far" data-icon="chevron-left" class="svg-inline--fa fa-chevron-left fa-w-8 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="currentColor" d="M231.293 473.899l19.799-19.799c4.686-4.686 4.686-12.284 0-16.971L70.393 256 251.092 74.87c4.686-4.686 4.686-12.284 0-16.971L231.293 38.1c-4.686-4.686-12.284-4.686-16.971 0L4.908 247.515c-4.686 4.686-4.686 12.284 0 16.971L214.322 473.9c4.687 4.686 12.285 4.686 16.971-.001z"></path></svg>                                    Back
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className={`col-lg-6 ${frontmatter.oneColumn ? "col-lg-12" : ""}`}>
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>
                    <div className={`col-lg-6 ${frontmatter.oneColumn ? "d-none" : ""}`}>
                        {images?.map((image, idx) => {
                            return <GatsbyImage className="mb-3" key={idx} image={getImage(image)} alt="" />
                        })}
                    </div>
                </div>
                <div className="d-flex justify-content-around">
                    {previous === false ? null : (
                        <>
                            {previous && (
                                <Link title={previous.frontmatter.title} className="link-nav-news" to={`/news${previous.fields.slug}`}>
                                    <button className="btn btn-info button-nav-news">Previous: {previous.frontmatter.title}</button>
                                </Link>
                            )}
                        </>
                    )}
                    {next === false ? null : (
                        <>
                            {next && (
                                <Link title={next.frontmatter.title} className="link-nav-news" to={`/news${next.fields.slug}`}>
                                    <button className="btn float-end btn-info button-nav-news">Next: {next.frontmatter.title}</button>
                                </Link>
                            )}
                        </>
                    )}
                </div>
            </div>
        </Layout>
    )
}
export default PressItem

export const query = graphql`
    query HoldingsPressItem($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            body
            frontmatter {
                author
                title
                devision
                oneColumn
                date(formatString: "Do MMMM YYYY")
                embeddedImagesLocal {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
                embeddedCoverImageLocal {
                    childImageSharp {
                        gatsbyImageData(aspectRatio: 4)
                    }
                }
            }
        }
    }
`
